<script lang="ts" context="module">
  import { datetime, TemporalZonedDateTimeInterval } from "$utils/temporal";
  import { toSVG as svgbarcode } from "bwip-js";
  function barcode(permit: Permit) {
    if (!permit) return "";

    const interval = TemporalZonedDateTimeInterval.from(
      permit.valid.interval,
      permit.timezone
    );

    //try {
    return svgbarcode({
      bcid: "pdf417",
      //bcid: "datamatrixrectangularextension",
      // text: `${permit.id} ${startstr} until ${
      //   endstr || "revoked"
      // }`.toUpperCase(),
      text:
        (permit.vehicle ? `VEHICLE=${permit.vehicle.display} / ` : "") +
        `START=${datetime(interval?.minimum)} / END=${datetime(interval?.maximum) || "not set"} / RECORD#=${permit.number} ${permit.id}`,
      binarytext: true,
      scale: 1,
      height: 6,
      padding: 0,
      //rows: 6,
      //columns: 10,
      includetext: false,
    });
    // } catch {
    //   return "";
    // }
  }
</script>

<script lang="ts">
  export let permit: Permit;
</script>

<figure class="barcode">
  {@html barcode(permit)}
</figure>
