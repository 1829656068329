<script lang="ts">
  export let permit: Permit;
</script>

{#if permit}
  <dl>
    {#if !permit.cancelled && permit.policy}
      <dt>Usage</dt>
      <dd>
        {permit.exempt ? "Not counted" : "Counted"}
      </dd>
    {/if}
  </dl>
{/if}
