<script lang="ts" context="module">
  import { writable } from "svelte/store";
  import { parsejwt } from "$utils/jwt";
  const unitTenantJwt = writable<Record<string, string>>({});

  function unitFromJwt(jwt: string, units: Units): Unit | null {
    if (!jwt) return null;
    if (!units) return null;
    const payload = parsejwt(jwt);
    if (!payload) return null;
    // eval payload exp
    if (payload.exp && payload.exp < Date.now() / 1000) return null;
    return units.items?.[payload.sub] ?? units.items?.[payload.unit];
  }
</script>

<script lang="ts">
  // this component forces a tenant selection, and if the policy requires authentication, it will also force a token to be generated and stored before running any slot content

  import TenantField from "$components/tenant/TenantField.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import TenantAuth from "$components/tenant/TenantAuth.svelte";
  import ValueField from "$components/form/ValueField.svelte";
  import PolicyError from "./PolicyError.svelte";
  import PolicyPermitComplete from "./PolicyPermitComplete.svelte";
  import { errored } from ".";

  export let policy: PermitIssuePolicy;
  export let values: Record<string, string | nullish> = {};

  export let error: any | nullish = null;

  let field = policy?.permit?.tenant;
  let name = field?.param ?? "tenant";
  let value: Tenant | Unit | string | nullish = values[name];

  let source = policy?.units as Units;
  let issingle: boolean = false;

  let unit: Unit | null;
  //let jwt: string | null;

  // run init on any change

  $: field = policy?.permit?.tenant;
  $: if (field) name = field.param;

  $: request = field?.request;
  $: required = field?.required;

  $: value = values[field?.param ?? name]; // pull from policy
  $: err = errored(error, field?.param ?? name);

  $: source = policy?.units as Units;
  $: unit = init(value, source);
  // $: item = (value as Tenant)?.id ? (value as Tenant) : null;
  $: jwt = unit ? $unitTenantJwt[unit.id] : null;

  $: issingle = single(source);
  // $: single =
  //   Object.values(source?.items ?? {}).length === 1
  //     ? Object.values(source?.items ?? {})[0]
  //     : null;

  $: if (jwt && value && jwt !== value) change(jwt);

  $: authrequired = !!field?.authentication?.required;

  // we need property for lookup I believe?
  $: property = policy.property as Property;

  // policy media field will intercept a media set, validate it, and either pass it up or error

  const eventing = createEventDispatcher<{
    change: typeof values;
  }>();

  function change(updated: typeof value) {
    logger("policy tenant step change=", updated, value, source, unit, jwt);
    eventing("change", {
      [name]: (updated as Unit | Tenant)?.id || (updated as string),
    });
  }

  function auth(value: { subject: string; token: string }) {
    unitTenantJwt.update(($values) => {
      $values[value.subject] = value.token;
      if (unit?.id) $values[unit.id] = value.token;
      return $values;
    });
    change(value.token);
  }

  function init(init: typeof value, units: typeof source): Unit | null {
    //logger("policy tenant step init=", init);
    if (!init) {
      return Object.values(units?.items ?? {}).length === 1
        ? Object.values(units.items)[0]
        : null;
      //unit = null;
      //jwt = null;
      return null;
    } // can't do anything
    if (typeof init === "string") {
      // this could be an id or a jwt...
      let resolved: Unit | null = units?.items[init];
      if (resolved) return resolved;
      resolved = unitFromJwt(init, units);
      if (resolved) {
        // we have a unit and it was a jwt init, need to write the token
        //logger("policy tenant init jwt", init, resolved.id, jwt);
        auth({ subject: resolved.id, token: init });
        return resolved;
      }
    } else if (init.type === "tenant") {
      // this is a tenant
      return units?.items?.[(init as Tenant).subject as string];
    } else if (init.type === "unit") {
      // this is a unit
      return units?.items?.[init.id as string];
    }

    return null;

    // if (unit && jwtcache[unit.id] && jwtcache[unit.id] !== init) {
    //   eventing("change", jwtcache[unit.id]);
    // }
  }
  // handle the case where single is set?
  //$: if (single && single.id !== unit?.id) change(single.id);

  function single(values: typeof source): boolean {
    if (!values) return false;
    if (Object.values(values?.items ?? {}).length !== 1) return false;
    change(Object.values(values.items)[0]);
    return true;
  }

  onMount(() => {
    // logger(
    //   "policy space field mount=",
    //   policy,
    //   valid.toString(),
    //   source,
    //   values
    // );
    issingle = single(source);
  });
</script>

{#if request && authrequired && !jwt}
  <TenantAuth subject={unit} on:change={(e) => auth(e.detail)}>
    <UiFormFieldItem>
      <TenantField
        readonly={issingle}
        value={unit}
        {source}
        {required}
        on:change={(e) => change(e.detail)}
      />
      <PolicyError data={err} />
    </UiFormFieldItem>
  </TenantAuth>
{:else if request && authrequired && jwt}
  <UiFormFieldList>
    <UiFormFieldItem>
      <TenantField
        readonly={issingle}
        value={unit}
        {source}
        {required}
        on:change={(e) => change(e.detail)}
      />
      <PolicyError data={err} />
      <ValueField label="Passcode">
        <data class="jwt" value={jwt}>••••</data>
      </ValueField>
    </UiFormFieldItem>
  </UiFormFieldList>
{:else if request}
  <UiFormFieldList>
    <UiFormFieldItem>
      <TenantField
        readonly={issingle}
        value={unit}
        {source}
        {required}
        on:change={(e) => change(e.detail)}
      />
      <PolicyError data={err} />
    </UiFormFieldItem>
    <!-- {#if value && authrequired && !jwt}
      <UiFormFieldItem></UiFormFieldItem>
    {/if} -->
  </UiFormFieldList>
{/if}
{#if err}
  <PolicyPermitComplete {policy} />
{/if}
{#if !err && (!request || !authrequired || jwt)}
  <slot />
{/if}
