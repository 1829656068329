import {
  base,
  viewpoint,
  responseJson,
  resolveProperty,
} from "$utils/api";
import { formdata } from "$utils/formdata";

export function resolvePolicies(value: any, state: any): PermitIssuePolicies {
  if (!value) return value;

  for (const [k, v] of Object.entries(value.items ?? {})) {
    value.items[k] = resolvePolicy(v, state) ?? resolvePolicy(k, state);
  }

  // expand for
  for (const [k, v] of Object.entries(value["for"] ?? {})) {
    resolvePolicies(v, state);
  }

  return value;
}


export function resolvePolicy(value: string, state: any): PermitIssuePolicy | nullish {
  if (!value || !state) return null;
  const items = state.items || state;
  const item = items[value] || value;
  if (!item) return item;
  if (typeof item === "string") return null;

  if (!item.id) return null;

  if (item.scope) item.property = resolveProperty(item.scope, state);

  for (const key of [
    "statistics",
    "pricing",
    "metered",
    "entry",
    "access",
    "spaces",
    "units",
    "availability",
    "validity",
  ]) {
    //const value =
    item[key] =
      state[key]?.["for"]?.[item.id] || state[key]?.["for"]?.[item.subject];
    // if (value?.items)
    //   for (const [k2, v2] of Object.entries(value.items))
    //     value.items[k2] = state[v2] ?? state[k2] ?? v2;
  }
  for (const key of ["units", "spaces"]) {
    if (item[key])
      item[key] = Object.entries(item[key].items ?? item[key]).reduce(
        (result, [k, v]) => {
          if (!v) return result;
          result.items[k] = items[v as string] ?? items[k] ?? v;
          for (const ip of ["pricing", "availability"]) result.items[k][ip] = state[ip]?.["for"]?.[result.items[k].id] ?? result.items[k][ip];
          return result;
        },
        item[key].items ? item[key] : { items: {} }
      );
  }

  for (const key of ["availability"]) {
    if (item[key])
      for (const [key2, value2] of Object.entries(item[key].for ?? item[key].items)) {
        if (value2.subject)
          value2.subject = items[value2.subject] ?? value2.subject;
      }
  }

  // if (item.units)
  //   item.units = Object.entries(item.units?.items ?? item.units).reduce(
  //     (result, [k, v]) => {
  //       if (!v) return result;
  //       result.items[k] = items[v] ?? items[k] ?? v;
  //       return result;
  //     },
  //     item.units.items ? item.units : { items: {} }
  //   );

  // item.statistics =
  //   get(state, ["statistics", "for", item.id]) ||
  //   get(state, ["statistics", "for", item.subject]);
  // item.pricing =
  //   get(state, ["pricing", "for", item.id]) ||
  //   get(state, ["pricing", "for", item.subject]);
  // item.metered =
  //   get(state, ["metered", "for", item.id]) ||
  //   get(state, ["metered", "for", item.subject]);
  // item.metered =
  //   get(state, ["metered", "for", item.id]) ||
  //   get(state, ["metered", "for", item.subject]);
  // for (let [id, v] of Object.entries(metersFromPolicy(item) || {})) {
  //   v = items[v] || v;
  //   if (!v || !v.principals) continue;
  //   for (const [id2, v2] of Object.entries(v.principals)) {
  //     v.principals[id2] = items[v2] || v2;
  //   }
  // }

  //item.property = resolveProperty(item.location, state);

  //logger("policy=", item);

  return item;
}

export async function fetchPolicy(policy: string): Promise<PermitIssuePolicy> {
  const res = await fetch(
    `${base()}/permits/policies/issue?policy=${policy}&viewpoint=${viewpoint()}&public=true&admin=false&disabled=false&availability=policy&validity=true&pricing=policy&pricing=space`
  );

  const json = await res.json();

  var item = resolvePolicy(policy, json);

  return item;
}

export async function fetchPolicies(scope: string): Promise<PermitIssuePolicies> {
  const res = await fetch(
    `${base()}/permits/policies/issue?scope=${scope}&viewpoint=${viewpoint()}&public=true&admin=false&disabled=false&pricing=policy`
  );

  const json = await res.json();



  return resolvePolicies(json.policies, json);
}


export async function fetchCreatePolicyPermitSpaceLock(
  policy: string,
  space: string,
) {

  const data: Record<string, string> = {
    policy,
    space,
  };

  const url = new URL(`${base()}/permits/policies/issue/spaces/lock?viewpoint=${viewpoint()}`);
  // url.searchParams.set("viewpoint", viewpoint());
  // url.searchParams.set("Authorization", authHeader);
  url.searchParams.set("space", space);
  url.searchParams.set("policy", policy);



  return fetch(url, {
    method: "POST",
    body: formdata(data),
  }).then(responseJson);
}

export async function fetchDeletePolicyPermitSpaceLock(
  token: string
) {

  const data: Record<string, string> = {
    lock: token,
  };

  const url = new URL(`${base()}/locked?viewpoint=${viewpoint()}`);
  url.searchParams.set("lock", token);



  return fetch(url, {
    method: "DELETE",
    body: formdata(data),
  }).then(responseJson);
}
