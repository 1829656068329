<script context="module" lang="ts">
  import { instant, iso } from "$utils/temporal";

  const now = instant({ seconds: 30 });
</script>

<script lang="ts">
  import { lifecycle, statusTitle, valid as isValid } from ".";
  export let permit: Permit;
  export let valid: boolean = false;

  $: value = lifecycle(permit, $now.toInstant());
  $: title = statusTitle(permit, $now.toInstant());
  $: valid = isValid(permit, $now.toInstant());
</script>

<time class="valid status" datetime={iso($now)}>
  <data class="status" {value}>
    {title}
  </data></time
>
