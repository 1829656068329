<script lang="ts">
  import ShareButton from "$components/share/ShareButton.svelte";
  import { indefinite } from "$utils/temporal";
  import { interval, unended } from ".";

  export let permit: Permit;

  $: valid = interval(permit);
</script>

{#if unended(permit)}
  <nav class="actions">
    <ul>
      {#if !indefinite(valid?.maximum)}
        <li>
          <a
            rel="alternate"
            type="text/calendar"
            href="https://calendar.smartpermit.app/events/{permit.id}"
            >Add to Calendar</a
          >
        </li>
      {/if}
      <li>
        <ShareButton url={new URL(document.URL)} title={permit.title} />
      </li>
    </ul>
  </nav>
{/if}
