<script lang="ts">
  import EntryItem from "$components/entry/EntryItem.svelte";
  import { unended } from ".";

  export let permit: Permit;

  $: entry = permit?.access ?? permit?.entry;

  $: console.log("entry=", entry);
</script>

{#if entry && unended(permit)}
  <ul class="entry">
    {#each Object.values(entry.items) as item}
      <li>
        <EntryItem {item} {permit} />
      </li>
    {/each}
  </ul>
  <!-- <dl class="entry">
    <dt>Entry</dt>
    
  </dl> -->
{/if}
