<script lang="ts" context="module">
  function search(
    query: Readable<string>,
    index: Index | MiniSearch,
    items: Readable<Units>
  ): Readable<Unit[]> {
    if (index instanceof MiniSearch) {
      //logger("setup with minisearch", index);
      return derived([query, items], ([$query, $items], set) => {
        // logger("$query=", $query);
        // logger("searched=", index.search($query));

        if ("" == $query || null == $query) return set([]);
        //set(
        Promise.resolve(
          uniqBy(
            orderBy(
              index.search($query),
              ["score", "addr:unit", "display"],
              ["desc", "asc", "asc"]
            ),
            "id"
          )

          // , function ($values: string[]) {
          //   set($values.map(($id) => $items.items[$id]).filter(Boolean));
          // });
          // .then(($values) =>
          //   set($values.map(($id) => $items.items[$id]).filter(Boolean))
          // )
        ).then(($values) => {
          //logger("minisearch results=", $values);
          set($values.map(($value) => $items.items[$value.id]).filter(Boolean));
        });
      });
    }
    return derived([query, items], ([$query, $items], set) => {
      logger("$query=", $query, index);
      logger("searched=", index.search($query));
      //set(
      Promise.resolve(index.search($query, 100))
        // , function ($values: string[]) {
        //   set($values.map(($id) => $items.items[$id]).filter(Boolean));
        // });
        .then(($values) =>
          set($values.map(($id) => $items.items[$id]).filter(Boolean))
        );
      // .then(set);
      //     .map(($id) => $items?.items[$id])
      //     .filter(Boolean)
      // ); //.then(set);
    });
  }
</script>

<script lang="ts">
  //import MiniSearch from "minisearch";
  import RecordItem from "$components/record/RecordItem.svelte";
  import type { Readable } from "svelte/store";
  import { writable, derived } from "svelte/store";
  //import StringIndex from "flexsearch/dist/module/index";
  //import WorkerIndex from "flexsearch/dist/module/worker/index";
  //import Document from "flexsearch/dist/module/document";
  import type { Index } from "flexsearch";
  import MiniSearch from "minisearch";
  import uniqBy from "lodash-es/uniqBy";
  import orderBy from "lodash-es/orderBy";

  const term = writable<string>();
  const values = writable<Units>();

  export let source: Units;

  export let query: string;

  // const flexsearch = new Document({
  //   //worker: true,

  //   index: ["display", "addr:unit", "building:ref", "addr:street"],
  // });

  // const flexsearch = new StringIndex<string>({
  //   worker: true,
  //   context: true,
  // });

  const minisearch = new MiniSearch({
    fields: [
      "display",
      "addr:unit",
      "addr:housenumber",
      "building:ref",
      "addr:street",
    ], // fields to index for full-text search
    searchOptions: {
      prefix: true,
      boost: { display: 2 },
    },
    storeFields: ["id", "display", "addr:unit"],
    extractField: (document, fieldName) =>
      fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
  });

  const searcher = search(term, minisearch, values);

  $: term.set(query); // update term store on each change

  $: if (source) {
    for (const item of Object.values(source.items)) {
      minisearch.add(item);
      // flexsearch.update(
      //   item.id,
      //   [
      //     item["addr:unit"],
      //     item["addr:housenumber"],
      //     item["addr:street"],
      //     item["building:ref"],
      //     item.display,
      //   ]
      //     .filter(Boolean)
      //     .join(" ")
      // );
      // logger(
      //   "indexed=",
      //   item.id,
      //   [
      //     item["addr:housenumber"],
      //     item["addr:street"],
      //     item["addr:unit"],
      //     item["building:ref"],
      //     item.display,
      //   ]
      //     .filter(Boolean)
      //     .join(" ")
      // );
    }
    // index.removeAll();
    // index.addAll(Object.values(source.items));
    values.set(source);
  }

  $: results = query && $searcher; //(flexsearch.search(query, { limit: 10 }) as Unit[]); //index.search(query).map((result) => units.items[result.id]);

  //$: logger("results=", results);
</script>

{#if results}
  <ul class="results">
    {#each results as item}
      <li>
        <a
          class="unit record"
          href="{location.pathname}?selected={item.id}&level={item.level || ''}"
          ><RecordItem {item} /></a
        >
      </li>
    {/each}
  </ul>
{/if}
