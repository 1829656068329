<script lang="ts">
  import Time from "$components/temporal/Time.svelte";
  import Loading from "$components/util/Loading.svelte";
  import { datetime } from "$utils/temporal";
  import PermitIssued from "./PermitIssued.svelte";
  import PermitUsage from "./PermitUsage.svelte";

  export let permit: Permit;

  // $: revoked = permit?.revoked
  //   ? Temporal.Instant.from(permit.revoked.datetime).toZonedDateTimeISO(
  //       permit.timezone
  //     )
  //   : null;
</script>

{#if permit}
  <PermitIssued {permit} />
  <PermitUsage {permit} />
  <dl>
    <dt>Synced</dt>
    <dd>
      {#if permit.generated}
        <Time datetime={permit.generated} />
      {:else}
        <Loading />
      {/if}
    </dd>
  </dl>
{/if}
