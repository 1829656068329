import { viewpoint, responseJson, base } from "$utils/api";

export async function fetchRequestPhysicalAccess(permit, via) {
    permit = permit?.id || permit;

    if (!permit) return null;

    const res = await fetch(`${base()}/entry/request?viewpoint=${viewpoint()}&permit=${permit}&via=${via}`,
        {
            method: "POST",
            //body: formData,
        }
    );

    const json = await responseJson(res);

    if (null == json) return json;

    return Object.values(json.physicalaccess?.results ?? {})[0];

    return json;
}