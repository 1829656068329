
import { permits } from "./api";
import { TemporalInstantInterval, TemporalZonedDateTimeInterval } from "$utils/temporal";

export {
    permits
};

export function interval(permit: Permit): TemporalZonedDateTimeInterval | null {
    return TemporalZonedDateTimeInterval.from(
        permit?.valid.interval,
        permit.timezone
    );
}

export function valid(permit: Permit, now?: Temporal.Instant): boolean {
    return lifecycle(permit, now ?? Temporal.Now.instant()) === "valid";
}

export function cancelled(permit: Permit): boolean {
    return !!permit?.cancelled;
}
export function revoked(permit: Permit): boolean {
    return !!permit?.revoked;
}

export function unended(permit: Permit, now?: Temporal.Instant): boolean {
    const valid = interval(permit);

    if (!now) now = Temporal.Now.instant();

    if (!valid?.maximum) return true;
    return Temporal.Instant.compare(now, valid.maximum.toInstant()) < 0;

}

export function unstarted(permit: Permit, now?: Temporal.Instant): boolean {
    const valid = interval(permit);

    if (!now) now = Temporal.Now.instant();

    if (!valid?.minimum) return false;
    return Temporal.Instant.compare(now, valid.minimum.toInstant()) < 0;
}

export function ended(permit: Permit, now?: Temporal.Instant): boolean {
    const valid = interval(permit);

    if (!now) now = Temporal.Now.instant();

    if (!valid?.maximum) return false;
    return Temporal.Instant.compare(now, valid.maximum.toInstant()) > 0;
}

const statustitles: Record<string, string> = {
    "valid": "Active",
    "revoked": "Revoked",
    "cancelled": "Cancelled",
    "canceled": "Canceled",
    "changed": "Changed",
    "invalid": "Ended",
    "invalidating": "Ending Soon",
    "pending": "Future",
    "prevalid": "Future",
    "validating": "Starting Soon",
}

export function statusTitle(permit: Permit, now: Temporal.Instant): string {

    if (permit.revoked) return permit.revoked.title;

    var life = lifecycle(permit, now);

    //if(life === "invalid" && revoked(permit)) life = "revoked";
    return statustitles[life] || "Unknown";
}

const stages = ["pending", "prevalid", "validating", "invalidating", "valid", "invalid"];

export function lifecycle(permit: Permit, now: Temporal.Instant): string {
    // custom cases...do we need?
    if (cancelled(permit)) return "cancelled";
    if (permit.revoked) return permit.revoked.title.toLowerCase();

    for (const stage of stages) {
        const interval = permit.lifecycle[stage];
        if (!interval) continue;
        if (!interval.includes("/")) continue;
        if (TemporalInstantInterval.from(interval)?.contains(now)) return stage;
    }
    return "indeterminate";
}